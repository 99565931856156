import { Col, Nav, Row, Tab,Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function FeesPayment(){
  return(
    <>
    <div  style={{ backgroundColor: "#f3f5f4" }}>
    <section className="Profiles ">
  
  <div className="container-fluid">
    <div className=" py-3 d-flex">
    
    <h3 className="text-white mx-auto">Fees Payment</h3> </div>     
   
  </div>
</section>
<section className="w-100">
  <div className="container">
    <div className="row  mx-auto d-block">

    <Tab.Container defaultActiveKey="Unpaid">
      <Row>
        <Col md={6} className="mx-auto d-block mt-4">
          <Nav variant="pills" className=" justify-content-between">
            <Nav.Item>
              <Nav.Link eventKey="Unpaid" className="Test-btn" >Unpaid</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="paid" className="Test-btn " >Paid</Nav.Link>
            </Nav.Item>
        
          </Nav>
        </Col>
        <Col md={12}>
          <Tab.Content>
            <Tab.Pane eventKey="Unpaid">  
     

      <div className="col-12 bg-white  rounded-2  p-3 my-4 ">
        <div className="row">
        <div className="col-md-2">    <img src="./images/receipt_qwzcdqjsun.svg" alt=""/> </div>
        <div className="col-md-8">   <h5>J P School Fee</h5>
        <p>Samantha Smith</p>
        <div className="rupee d-flex">
          <i className="bi bi-currency-rupee fw-bold"></i>
    <p className="fw-bold">30.50</p>
        </div></div>
        <div className="col-md-2 align-self-end  ">  
        <button className="Pay-Now d-block  ms-auto">Pay Now</button>
       </div>

        </div>
    

    
      </div>
      <div className="col-12 bg-white  rounded-2  p-3  ">
        <div className="row">
        <div className="col-md-2">    <img src="./images/receipt_qwzcdqjsun.svg" alt=""/> </div>
        <div className="col-md-8">   <h5>J P School Fee</h5>
        <p>Johnson Smith</p>
        <div className="rupee d-flex">
          <i className="bi bi-currency-rupee fw-bold"></i>
    <p className="fw-bold">50.50</p>
        </div></div>
        <div className="col-md-2 align-self-end  ">  
        <button className="Pay-Now d-block  ms-auto">Pay Now</button>
       </div>

        </div>
    

    
      </div>
      <div className="col-12 bg-white  rounded-2  p-3  my-4">
        <div className="row">
        <div className="col-md-2">    <img src="./images/receipt_qwzcdqjsun.svg" alt=""/> </div>
        <div className="col-md-8">   <h5>J P School Fee</h5>
        <p>Surendra Smith</p>
        <div className="rupee d-flex">
          <i className="bi bi-currency-rupee fw-bold"></i>
    <p className="fw-bold">20.50</p>
        </div></div>
        <div className="col-md-2 align-self-end  ">  
        <button className="Pay-Now d-block  ms-auto">Pay Now</button>
       </div>

        </div>
    

    
      </div>


    
      </Tab.Pane>
            <Tab.Pane eventKey="paid"> 
            <section className="w-100">
  <div className="container">
    <div className="row mx-auto">
    <Tabs
      defaultActiveKey="Detail"

      className="my-3"
    >
      <Tab eventKey="Detail" title="Detail">
      <div className="col-12  my-4 pt-2 d-flex justify-content-between">
 <h6>Paid Fee:</h6>
 <p>Rs 70,800.00</p>
      </div>
      <div className="col-12   d-flex justify-content-between">
        <h6>Due Fee:</h6>
        <p>Rs 0.00</p>
             </div>
             <hr/>
             <div className="col-12  my-4 d-flex justify-content-between">
              <h6>Total Fee:</h6>
              <p>Rs 70,800.00</p>
                   </div>
      </Tab>
      <Tab eventKey="Pay Online" title="Pay Online">
      <div className="col-12  my-4 pt-2 d-flex justify-content-between">
 <h6>Paid Fee:</h6>
 <p>Rs 70,800.00</p>
      </div>
      <div className="col-12   d-flex justify-content-between">
        <h6>Due Fee:</h6>
        <p>Rs 0.00</p>
             </div>
             <hr/>
             <div className="col-12  my-4 d-flex justify-content-between">
              <h6>Total Fee:</h6>
              <p>Rs 70,800.00</p>
                   </div>
      </Tab>
      <Tab eventKey="Fee Receipt" title="Fee Receipt" >
      <div className="col-12  my-4 pt-2 d-flex justify-content-between">
 <h6>Paid Fee:</h6>
 <p>Rs 70,800.00</p>
      </div>
      <div className="col-12   d-flex justify-content-between">
        <h6>Due Fee:</h6>
        <p>Rs 0.00</p>
             </div>
             <hr/>
             <div className="col-12  my-4 d-flex justify-content-between">
              <h6>Total Fee:</h6>
              <p>Rs 70,800.00</p>
                   </div>
      </Tab>
    </Tabs>

    {/* <div className=" mt-4 d-flex justify-content-around">
      <button className="Detail">Detail</button>
      <button className="Detail">Pay Online</button>
      <button className="Detail">Fee Receipt</button>
 

    </div> */}




    </div>
  </div>

</section> 
            </Tab.Pane>
 
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>


 
    


   


 
    </div>
  </div>

</section>


<div className="w-100">
  <img src="./images/g5566.svg" alt="" className="w-100"/>
</div>
    </div>
    </>
  )
}
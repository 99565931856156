import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>

      <section className="" style={{ backgroundColor: "#f3f5f4" }}>


        {/* <div className="container-fluid">
          <div className=" py-3 d-flex">
            <Link to="/Navbar">
              <i className="bi bi-three-dots-vertical text-white h2 "></i> </Link>
            <h2 className="text-white fw-bold mx-auto">J P School</h2> </div>
        </div>

        <div className="container">

          <div className="row">
            <div className="col-sm-6">
              <div className="box-1 mx-auto d-block my-4 ">
                <Link to="/Profile" className="d-flex Samantha">
                  <img src="./images/gunu.jpg" alt="" />
                  <div className="mx-4">
                    <span>Class VI A</span><br />
                    <h4 className="mt-2">Samantha Smith</h4></div>

                </Link>

              </div></div>
            <div className="col-sm-6">
              <div className="box-1 mx-auto d-block my-4 ">
                <Link to="/Communication" className="d-flex Samantha">
                  <img src="./images/message.svg" alt="" className="pb-2" />
                  <div className="mx-4">
                    <span> Class VI A</span><br />
                    <h4 className="mt-2">Communication</h4>
                    </div>
                </Link>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="box-2 mx-auto d-block my-4 ">
                <Link to="/Uniform" className="Samantha">
                  <div className="Samant "> <h4 className="mt-2">Uniform Store</h4>
                    <span className="">Class V</span>
                  </div>
                  <div className="bus">
                    <img src="./images/uniform_mwa59oqqqao0 1.svg" alt="" className="w-50 float-end pb-1 " />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="box-2 mx-auto d-block my-4 ">
                <Link to="/FeesPayment" className="Samantha">
                  <div className=" Samant"> <h4 className="mt-2">Fees Payment</h4>
                    <span className="">Know Pay info</span>
                  </div>
                  <div className="bus">
                    <img src="./images/wallet_9f0bgl56m18p 1.svg" alt="" className="w-75 float-end" /></div>
                </Link>
              </div>
            </div>
            <div className="col-md-4">   <div className="box-2 mx-auto d-block my-4  ">
              <Link to="/Attendance" className="Samantha">
                <div className="Samant "> <h4 className="mt-2">Attendance</h4>
                  <span className="">Presence at school</span>
                </div>
                <div className="bus">
                  <img src="./images/Attendance.svg" alt="" className=" w-75 float-end  " /></div>
              </Link>
            </div></div>

          </div>
          <div className="row">

            <div className="col-md-4">
              <div className="box-2 mx-auto d-block my-4 ">
                <Link to="/Calendar" className="Samantha">
                  <div className="Samant "> <h4 className="mt-2">Calender</h4>
                    <span className="">School Schedule</span>
                  </div>
                  <div className="bus">
                    <img src="./images/calendar_kyrvjn6u6kml 1.svg" alt="" className=" w-75 float-end pb-2" /></div>
                </Link>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="box-2 mx-auto d-block my-4  ">
                <Link to="/Calendar" className="Samantha">
                  <div className=" Samant"> <h4 className="mt-2">Tests</h4>
                    <span className="">Test & Results</span>
                  </div> <div className="bus">
                    <img src="./images/Tests.svg" alt="" className=" w-75 float-end pb-1" /></div>
                </Link>
              </div>
            </div>
            <div className="col-md-4">   <div className="box-2 mx-auto d-block my-4 ">
              <Link to="#" className="Samantha">
                <div className=" Samant"> <h4 className="mt-2">Syllabus</h4>
                  <span className="">Syllabus at className</span>
                </div>
                <div className="bus">
                  <img src="./images/syllabus.svg" alt="" className=" w-75 float-end " /></div>
              </Link>
            </div></div>

          </div>
          <div className="row">

            <div className="col-md-4">
              <div className="box-2 mx-auto d-block my-4 ">
                <Link to="/Assignment" className="Samantha">
                  <div className="Samant "> <h4 className="mt-2">Assignment</h4>
                    <span className="">School Schedule</span>
                  </div>
                  <div className="bus">
                    <img src="./images/assignment.svg" alt="" className=" w-75 float-end" /></div>
                </Link>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="box-2 mx-auto d-block my-4 ">
                <Link to="/TimeTable " className="Samantha">
                  <div className="Samant "> <h4 className="mt-2">Time Table</h4>
                    <span className="">Test & Results</span>
                  </div>
                  <div className="bus">
                    <img src="./images/time-table.svg" alt="" className="w-100 float-end pb-1" /></div>
                </Link>
              </div>
            </div>
            <div className="col-md-4">   <div className="box-2 mx-auto d-block my-4">
              <Link to="/Gallery" className="Samantha">
                <div className=" Samant"> <h4 className="mt-2">Gallery</h4>
                  <span className="">All of images</span>
                </div>
                <div className="bus">
                  <img src="./images/gallery_whdcrkni1u9i1.svg" alt="" className="w-75 float-end" /></div>
              </Link>
            </div></div>

          </div>
        </div>
        <div className="w-100">
          <img src="./images/g5566.svg" alt="" className="w-100" />
        </div>
      </section>

    </>
  )
}
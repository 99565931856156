import { Col, Nav, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Calendar(){
  return(
    <>
      <section class="Profiles ">
  
  <div class="container-fluid">
    <div class=" py-3 d-flex">
    <h3 class="text-white mx-auto">Calendar</h3> </div>
    <div class=" mt-2 d-flex justify-content-around">
<p class="text-white">
  <i class="bi bi-chevron-left text-white  "></i>
  June,2023
  <i class="bi bi-chevron-right text-white  "></i>
</p>
 

    </div>             
 
  </div>
</section>
<section class="w-100">
<div class="container">
    <div class="row ">
      <div class="col-md-8   mt-2 ">
        <img src="./images/Attendance-1.svg" alt="" class="w-100"/>

       </div>
       <div class="col-md-4   mt-5 ">
   
   
       
    
    <Tab.Container defaultActiveKey="Test">
      <Row>
        <Col sm={12}>
          <Nav variant="pills" className=" justify-content-between">
            <Nav.Item>
              <Nav.Link eventKey="Test"className=" Test-btn" >Test</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Event"className="Test-btn"  >Event</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Holiday" className="Test-btn" >Holiday</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={12}>
          <Tab.Content>
            <Tab.Pane eventKey="Test">   <div class="bg-light p-3 my-3 rounded-3">
        <div class=" d-flex justify-content-between">
        <p class="text-primary ">Test</p>
        <p class="fw-bold text-end">17 Jul, 2023 Mon</p>
      </div>
       <h6 class="fw-bold">Maths Test</h6>
      </div></Tab.Pane>
            <Tab.Pane eventKey="Event">    <div class="bg-light p-3 my-3 rounded-3">
        <div class=" d-flex justify-content-between">
        <p class="text-primary ">Event</p>
        <p class="fw-bold text-end">17 Jul, 2023 Mon</p>
      </div>
       <h6 class="fw-bold">Geometric weekly Event</h6>
      </div></Tab.Pane>
      <Tab.Pane eventKey="Holiday">
      <div class="bg-light p-3 my-3 rounded-3">
        <div class=" d-flex justify-content-between">
        <p class="text-primary ">Holiday</p>
        <p class="fw-bold text-end">03 Jun, 2023 Mon</p>
      </div>
       <h6 class="fw-bold">Public Holiday</h6>
      </div>
      </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
     
   
       </div>


    </div>
  </div>

</section>


<div class="w-100">
  <img src="./images/g5566.svg" alt="" class="w-100"/>
</div>
    </>
  )
}
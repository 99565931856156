import { Link } from "react-router-dom";

export default function Navbar(){
  return(
    <>
        <section className="Profiles " style={{ backgroundColor: "#f3f5f4" }}>
    <div className="container-fluid">
          <div className=" row py-3">
            <div className="col-md-4">
                     <nav role="navigation">
  <div id="menuToggle">
  
    <input type="checkbox" />
    
  
    <span></span>
    <span></span>
    <span></span>
    
   
    <ul id="menu">
      <Link to="/Uniform"> </Link>
      <Link to="/Uniform"><li>Uniform Store</li> </Link>
      <Link to="/FeesPayment"> <li>Fees Payment</li></Link>
      <Link to="/Attendance"> <li>Attendance</li></Link>
      <Link to="/Calendar"><li>Tests</li> </Link>
      <Link to="/Assignment"> <li>Assignment</li></Link>
      <Link to="/TimeTable"> <li>Time Table</li></Link>
      <Link to="/Gallery"> <li>Gallery</li></Link>

      <Link to="/Syllabus"><li>Syllabus</li> </Link>
      <Link to="/Calendar"><li>Calendar</li>  </Link>
    
    
    </ul>
  </div>
</nav></div>
            <div className="col-md-4">  
                <img src="./images/logo_light.png" alt="" className="w-75 d-block mx-auto" />
                 <h5 className="text-white px-5 text-center py-2">Riico Industrial Area,Alayala Road, Tranagar, 331304</h5>
            </div>
            <div className="col-md-4  text-end"><i class="bi bi-power  fw-bold   text-white  fs-2"></i></div>
   
       
          </div>
        </div>

        <div className="container">

          <div className="row">
            <div className="col-sm-6">
              <div className="box-1 mx-auto d-block my-4 ">
                <Link to="/Profile" className="d-flex Samantha">
                  <img src="./images/gunu.jpg" alt="" />
                  <div className="mx-4">
                    <span>Class VI A</span><br />
                    <h4 className="mt-2">Samantha Smith</h4></div>

                </Link>

              </div></div>
            <div className="col-sm-6">
              <div className="box-1 mx-auto d-block my-4 ">
                <Link to="/Communication" className="d-flex Samantha">
                  <img src="./images/message.svg" alt="" className="pb-2" />
                  <div className="mx-4">
                    <span> Class VI A</span><br />
                    <h4 className="mt-2">Communication</h4>
                    </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        </section>
    </>
  )
} 
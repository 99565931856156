import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';


export default function Profilform(){

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return(
    <>
       <Button variant="primary" onClick={handleShow} className=' align-self-end'>
       Edit
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Personal Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
             <form >
      <div className="row p-4">
        {/* <h5 className="text-primary pb-2"></h5> */}
        <div className="col-md-6 "> <label for="exampleInputPhone" className="form-label">Phone No:</label>
          <input type="mobaile" className="form-control" id="exampleInputPhone" placeholder="766305762"/></div>
        <div className="col-md-6 ">    <label for="exampleInputDOB" className="form-label">DOB:</label>
          <input type="date" id="DOB" name="DOB" className="form-control"/></div>
  <div className="col-md-6 py-3">    <label for="exampleInputclassNameTeacher" className="form-label">class Teacher Name:</label>
    <input type="text" className="form-control" id="exampleInputclassNameTeacher"/></div>
    <div className="col-md-6 py-3">  <label for="exampleInputFather" className="form-label">Father’ s Name:</label>
      <input type="text" className="form-control" id="exampleInputFather"/></div>
    <div className="col-md-6  ">  <label for="exampleInputMother" className="form-label">Mother’ s Name</label>
      <input type="text" className="form-control" id="exampleInputMother"/></div>
      <div className="col-md-6 
      ">
        <label for="exampleInputEmail1" className="form-label">Email address</label>
        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
      </div>
      <div className="col-md-8 py-3">
        <label for="exampleInputEmail1" className="form-label">Address</label>
        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
      </div>
      </div>
    </form> 
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
import React, { createContext, useState } from 'react'

export const AuthProvider = createContext()
export default function AuthContext({children}) {
  const [auth,setAuth] = useState(false)
  return (
    <AuthProvider.Provider value={{auth,setAuth}}>
      {children}
    </AuthProvider.Provider>
  )
}
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthProvider } from "../context/AuthContext";

export default function Index () {
  const {auth, setAuth} = useContext(AuthProvider)
  const navigte = useNavigate()
 const handleLogin =() =>{
  navigte("/")
  setAuth(true)
 }
  return(
    <>
  <div className="login-img">
           
           <div className="container">
          
                <div className="row">
                     <div className="col-sm-12  align-self-center">
                          <form className="forme ">
                          <img src="./images/logo-smaller-222.png" alt="" className="w-75 mx-auto d-block py-3" style={{width: "30%"}}/>
                       
                         
                          <input type="Username" className="form-control my-3" placeholder="Username" aria-describedby="UsernameHelp"/>
                          <input type="Password" className="form-control mt-4" placeholder="Password" aria-describedby="PasswordHelp"/>
                          <div className="py-2 mx-auto d-block text-alaine-center">
                               <input type="checkbox" className="form-check-input" />
                         
                               <a href="#"> Remember me</a>  
                          </div>

                          {/* <button onClick={e=>setAuth(true)} type="Sign-in" className=" Sign-in px-5 my-4 mx-auto d-block text-white">
                         Sign in</button> */}
                         <button type="button" className=" Sign-in px-5 my-4 mx-auto d-block text-white" onClick={handleLogin}>Sing in</button>
                     </form>
                     </div>
                </div> 

        
      </div>
  
      </div>



    </>
  )
}


import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';



export default function TimeTable(){
  
  return(
    <>
  <section className="Profiles ">
  
  <div className="container-fluid">
    <div className=" py-3 d-flex">
    <h3 className="text-white  mx-auto">Time Table</h3> </div>
    

  </div>
</section>
<section className="w-100">
<div className="container-fluid">


<Tab.Container  defaultActiveKey="Mond" >
      <Row className='mt-4'>
        <Col sm={12}>
          <Nav variant="pills" className=" justify-content-between">
            <Nav.Item>
              <Nav.Link eventKey="Mond"className=" Test-btn my-2" >Mon 24/07/2023</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Tue"className="Test-btn  my-2"  >Tue 25/07/2023</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Wed" className="Test-btn my-2" >Wed 26/07/2023</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Thu" className="Test-btn my-2" >Thu 27/07/2023</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Fri" className="Test-btn my-2" >Fri 28/07/2023</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Sat" className="Test-btn my-2" >Sat 29/07/2023</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={12}>
          <Tab.Content className='mt-5'>
            <Tab.Pane eventKey="Mond"> 
            <div>
                <div className="row mx-auto">
      <div className="col-12  mt-2  d-flex  justify-content-around">
 <h6  className="fw-bold"> TIME</h6>
 <h6  className="fw-bold">CLASS</h6>
 <h6  className="fw-bold">SUBJECT</h6>
       </div>
      <div className="col-12 pt-3  d-flex  justify-content-around bg-light">
   <p>08:00 - 8:30</p>
   <p>1A</p>
   <p>English</p>
      </div>
      <div className="col-12 pt-3 d-flex justify-content-around">
        <p>08:30 - 9:00</p>
        <p>2B</p>
       <p>Social</p>
   </div>

  <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
         <p>09:00 - 9:15</p>
         <p>Recess</p>
        <p>15 min</p>
    </div>
    <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>09:15 - 9:45</p>
      <p>1A</p>
      <p>English</p>
         </div>
    <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>09:45 - 10:15</p>
          <p>1B</p>
         <p>English</p>
     </div>
     <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>10:15 - 10:45</p>
      <p>1B</p>
      <p>15 min</p>
         </div>
         <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>10:45 - 11:15</p>
          <p>2A</p>
         <p>English</p>
     </div>
     <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>11:15 - 11:45</p>
      <p>1A</p>
      <p>Social</p>
         </div>

         <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>11:45 - 12:15</p>
          <p>2B</p>
         <p>English</p>
     </div>

    </div>
                  </div></Tab.Pane>
                  <Tab.Pane eventKey="Tue"> 
                  <div>
                <div className="row mx-auto">
      <div className="col-12  mt-2  d-flex  justify-content-around">
 <h6  className="fw-bold"> TIME</h6>
 <h6  className="fw-bold">CLASS</h6>
 <h6  className="fw-bold">SUBJECT</h6>
       </div>
      <div className="col-12 pt-3  d-flex  justify-content-around bg-light">
   <p>08:00 - 8:30</p>
   <p>1A</p>
   <p>English</p>
      </div>
      <div className="col-12 pt-3 d-flex justify-content-around">
        <p>08:30 - 9:00</p>
        <p>2B</p>
       <p>Social</p>
   </div>

  <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
         <p>09:00 - 9:15</p>
         <p>Recess</p>
        <p>15 min</p>
    </div>
    <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>09:15 - 9:45</p>
      <p>1A</p>
      <p>English</p>
         </div>
    <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>09:45 - 10:15</p>
          <p>1B</p>
         <p>English</p>
     </div>
     <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>10:15 - 10:45</p>
      <p>1B</p>
      <p>15 min</p>
         </div>
         <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>10:45 - 11:15</p>
          <p>2A</p>
         <p>English</p>
     </div>
     <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>11:15 - 11:45</p>
      <p>1A</p>
      <p>Social</p>
         </div>

         <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>11:45 - 12:15</p>
          <p>2B</p>
         <p>English</p>
     </div>

    </div>
                  </div></Tab.Pane>
                  <Tab.Pane eventKey="Wed"> 
            <div>
                <div className="row mx-auto">
      <div className="col-12  mt-2  d-flex  justify-content-around">
 <h6  className="fw-bold"> TIME</h6>
 <h6  className="fw-bold">CLASS</h6>
 <h6  className="fw-bold">SUBJECT</h6>
       </div>
      <div className="col-12 pt-3  d-flex  justify-content-around bg-light">
   <p>08:00 - 8:30</p>
   <p>1A</p>
   <p>English</p>
      </div>
      <div className="col-12 pt-3 d-flex justify-content-around">
        <p>08:30 - 9:00</p>
        <p>2B</p>
       <p>Social</p>
   </div>

  <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
         <p>09:00 - 9:15</p>
         <p>Recess</p>
        <p>15 min</p>
    </div>
    <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>09:15 - 9:45</p>
      <p>1A</p>
      <p>English</p>
         </div>
    <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>09:45 - 10:15</p>
          <p>1B</p>
         <p>English</p>
     </div>
     <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>10:15 - 10:45</p>
      <p>1B</p>
      <p>15 min</p>
         </div>
         <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>10:45 - 11:15</p>
          <p>2A</p>
         <p>English</p>
     </div>
     <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>11:15 - 11:45</p>
      <p>1A</p>
      <p>Social</p>
         </div>

         <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>11:45 - 12:15</p>
          <p>2B</p>
         <p>English</p>
     </div>

    </div>
                  </div></Tab.Pane>
                  <Tab.Pane eventKey="Thu"> 
            <div>
                <div className="row mx-auto">
      <div className="col-12  mt-2  d-flex  justify-content-around">
 <h6  className="fw-bold"> TIME</h6>
 <h6  className="fw-bold">CLASS</h6>
 <h6  className="fw-bold">SUBJECT</h6>
       </div>
      <div className="col-12 pt-3  d-flex  justify-content-around bg-light">
   <p>08:00 - 8:30</p>
   <p>1A</p>
   <p>English</p>
      </div>
      <div className="col-12 pt-3 d-flex justify-content-around">
        <p>08:30 - 9:00</p>
        <p>2B</p>
       <p>Social</p>
   </div>

  <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
         <p>09:00 - 9:15</p>
         <p>Recess</p>
        <p>15 min</p>
    </div>
    <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>09:15 - 9:45</p>
      <p>1A</p>
      <p>English</p>
         </div>
    <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>09:45 - 10:15</p>
          <p>1B</p>
         <p>English</p>
     </div>
     <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>10:15 - 10:45</p>
      <p>1B</p>
      <p>15 min</p>
         </div>
         <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>10:45 - 11:15</p>
          <p>2A</p>
         <p>English</p>
     </div>
     <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>11:15 - 11:45</p>
      <p>1A</p>
      <p>Social</p>
         </div>

         <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>11:45 - 12:15</p>
          <p>2B</p>
         <p>English</p>
     </div>

    </div>
                  </div></Tab.Pane>
                  <Tab.Pane eventKey="Fri"> 
            <div>
                <div className="row mx-auto">
      <div className="col-12  mt-2  d-flex  justify-content-around">
 <h6  className="fw-bold"> TIME</h6>
 <h6  className="fw-bold">CLASS</h6>
 <h6  className="fw-bold">SUBJECT</h6>
       </div>
      <div className="col-12 pt-3  d-flex  justify-content-around bg-light">
   <p>08:00 - 8:30</p>
   <p>1A</p>
   <p>English</p>
      </div>
      <div className="col-12 pt-3 d-flex justify-content-around">
        <p>08:30 - 9:00</p>
        <p>2B</p>
       <p>Social</p>
   </div>

  <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
         <p>09:00 - 9:15</p>
         <p>Recess</p>
        <p>15 min</p>
    </div>
    <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>09:15 - 9:45</p>
      <p>1A</p>
      <p>English</p>
         </div>
    <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>09:45 - 10:15</p>
          <p>1B</p>
         <p>English</p>
     </div>
     <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>10:15 - 10:45</p>
      <p>1B</p>
      <p>15 min</p>
         </div>
         <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>10:45 - 11:15</p>
          <p>2A</p>
         <p>English</p>
     </div>
     <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>11:15 - 11:45</p>
      <p>1A</p>
      <p>Social</p>
         </div>

         <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>11:45 - 12:15</p>
          <p>2B</p>
         <p>English</p>
     </div>

    </div>
                  </div></Tab.Pane>
                  <Tab.Pane eventKey="Sat"> 
            <div >
                <div className="row mx-auto">
      <div className="col-12  mt-2  d-flex  justify-content-around">
 <h6  className="fw-bold"> TIME</h6>
 <h6  className="fw-bold">CLASS</h6>
 <h6  className="fw-bold">SUBJECT</h6>
       </div>
      <div className="col-12 pt-3  d-flex  justify-content-around bg-light">
   <p>08:00 - 8:30</p>
   <p>1A</p>
   <p>English</p>
      </div>
      <div className="col-12 pt-3 d-flex justify-content-around">
        <p>08:30 - 9:00</p>
        <p>2B</p>
       <p>Social</p>
   </div>

  <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
         <p>09:00 - 9:15</p>
         <p>Recess</p>
        <p>15 min</p>
    </div>
    <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>09:15 - 9:45</p>
      <p>1A</p>
      <p>English</p>
         </div>
    <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>09:45 - 10:15</p>
          <p>1B</p>
         <p>English</p>
     </div>
     <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>10:15 - 10:45</p>
      <p>1B</p>
      <p>15 min</p>
         </div>
         <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>10:45 - 11:15</p>
          <p>2A</p>
         <p>English</p>
     </div>
     <div className="col-12 pt-3  d-flex  justify-content-around">
      <p>11:15 - 11:45</p>
      <p>1A</p>
      <p>Social</p>
         </div>

         <div className="col-12 pt-3 d-flex justify-content-around  bg-light">
          <p>11:45 - 12:15</p>
          <p>2B</p>
         <p>English</p>
     </div>

    </div>
                  </div></Tab.Pane>
        
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>


  </div>
</section>


<div className="w-100">
  <img src="./images/g5566.svg" alt="" className="w-100"/>
</div>

    </>
  )
} 
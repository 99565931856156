import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Navbar from '../pages/Navbar'
import { AuthProvider } from '../context/AuthContext'

export default function Layout() {
  const { auth, setAuth } = useContext(AuthProvider)
  return (
    auth ?
      <>
        <Navbar />
        <Outlet />
      </> : <Navigate to='/login' />
  )
}

import { Link } from "react-router-dom";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import UploadImage from "../Component/UploadImage";
import Profilform  from "../Component/Profilform";
export default function Profile() {


  return (
    <>
      <div style={{ backgroundColor: "#f3f5f4" }}>
        <section className="Profiles ">

          <div className="container-fluid">
            <div className=" py-3 d-flex">
              <h3 className="text-white  mx-auto">Profile</h3> </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              {/* <div className="">
          <img src="./images/profile-img.svg" alt="" className=""/>
        </div>  */}
              <div className="col py-3  position-relative" >
                <img src="./images/profile-img.svg" alt="" className="avatar avatar-xxl m-0 p-0 d-block mx-auto " />
            <UploadImage />
                </div>
            </div>
          </div>
        </section>
        <section className="w-100">
          <div className="container">
            <form action="" className="Personal">
              <div className="row p-4">
                <h5 className="text-primary pb-2">Personal Detail</h5>
                <div className="col-6 "> <label for="exampleInputPhone" className="form-label">Phone No</label>

                </div>
                <div className="col-6 ">    <label for="exampleInputDOB" className="form-label">DOB</label>
                </div>
                <div className="col-6">    <label for="exampleInputclassNameTeacher" className="form-label">class Teacher Name </label>
                </div>
                <div className="col-6 ">  <label for="exampleInputFather" className="form-label">Father’ s Name</label>
                </div>
                <div className="col-6  ">  <label for="exampleInputMother" className="form-label">Mother’ s Name</label>
                </div>
                <div className="col-6">
                  <label for="exampleInputEmail1" className="form-label">Email address</label>

                </div>
                <div className="col-6">
                  <label for="exampleInputEmail1" className="form-label">Address</label>

                </div>
                <div className="col-12 text-end" >
                <Profilform />
                </div>
        
          
              </div>
            </form>






         
          </div>
        </section>
        <div className="w-100">
          <img src="./images/g5566.svg" alt="" className="w-100" />
        </div>
      </div>
    </>
  )
}

import { Link } from "react-router-dom";

export default function Attendance(){
  return(
    <>
      <section className="Profiles ">
  
  <div className="container-fluid">
    <div className=" py-3 d-flex">
 
    <h3 className="text-white mx-auto">Attendance</h3> </div>
    
 
  </div>
</section>
<section className="w-100">
<div className="container">
    <div className="row ">
      <div className="col-md-6   mt-2 ">
        <img src="./images/Attendance-1.svg" alt="" className="w-100"/>

       </div>
       <div className="col-md-6   mt-5 ">
       
        <div className=" d-flex justify-content-between">
          <div>
          <p className="p-0 m-0">Present</p>
        <button type="button" className="btn btn-info">Days</button></div>
        <div>
        <p  className="p-0 m-0">Absent</p>
        <button type="button" className="btn btn-danger">Days</button></div>
        <div>
        <p  className="p-0 m-0">Holidays</p>
        <button type="button" className="btn btn-primary">Days</button></div></div>
        <div className="row py-4">
          <div className="col-md-8">
            <p>Yearly:</p>
            <img src="./images/pai-chart.svg" alt="" className="d-block mx-auto"/>

          </div>
          <div className="col-md-4">
            <div className="d-flex mt-5">
              <button type="button" className="btn btn-info py-3 px-1 "></button>
              <p className="p-0 mt-2 px-3 ">Present</p>
            </div>
            <div className="d-flex my-4">
              <button type="button" className="btn btn-danger py-3 px-1 "></button>
              <p className="p-0 mt-2 px-3 ">Absent</p>
            </div>
            <div className="d-flex ">
              <button type="button" className="btn btn-primary py-3 px-1 "></button>
              <p className="p-0 mt-2 px-3 ">Holidays</p>
            </div>

          </div>

        </div>
       </div>


    </div>
  </div>

</section>


<div className="w-100">
  <img src="./images/g5566.svg" alt="" className="w-100"/>
</div>
    </>
  )
}
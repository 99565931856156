import { Link } from "react-router-dom";

export default function Communication(){
 return(
  <>
    <section className="Profiles ">
  
  <div className="container-fluid">
    <div className=" py-3 d-flex">
    <h3 className="text-white  mx-auto">Communication</h3></div>      
  </div>
</section>

<section className="w-100 ">
  <div className="containers">
    <div className="row mt-5 p-4">
    <div className="col-sm-6 " >
      <h4  className="">28 - 07 - 2023</h4>
    </div>
    <div className="col-sm-6 " >
      <h4  className=" text-end">Friday</h4>
    </div>

    </div>
    <div className="row my-5 p-4 px-2 bg-light">
      <div className="col-md-6">
<h2>ENGLISH</h2>
      </div>
      <div className="col-md-6 text-end">
        <select name="cars" id="cars" className="border-0 h2  p-2 rounded-2 ">
          <option value="volvo " className="">Ended On 28-07-2023</option>
          <option value="saab">29-07-2023</option>
          <option value="opel">30-07-2023</option>
          <option value="audi">31-07-2023</option>
        </select>

    </div>
  </div>
  </div>
</section>
<div className="w-100">
  <img src="./images/g5566.svg" alt="" className="w-100"/>
</div>
  
  </>
 )
}
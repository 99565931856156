import { Link } from "react-router-dom";

export default function Gallery(){
  return(
    <>
      <section className="Profiles ">
  
  <div className="container-fluid">
    <div className=" py-3 d-flex">
    <h3 className="text-white  mx-auto">Gallery</h3> </div>      
  </div>
</section>
<section>
  <div className="container">
    <div className="row">
      <div className="col-md-6 py-3">
        <img src="./images/11.svg" alt="" className="mx-auto d-block"/>
      </div>
      <div className="col-md-6 py-3">
        <img src="./images/12.svg" alt="" className="mx-auto d-block"/>
      </div>

      <div className="col-md-12 py-3">
        <img src="./images/13.svg" alt="" className="mx-auto d-block"/>
      </div>
    </div>
  </div>
</section>

<div className="w-100">
  <img src="./images/g5566.svg" alt="" className="w-100"/>
</div>
    </>
  )
}
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Index from './pages/Index';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Communication from './pages/Communication';
import Uniform from './pages/Uniform';
import FeesPayment from './pages/FeesPayment';

import Attendance from './pages/Attendance';
import Calendar from './pages/Calendar';
import Assignment from './pages/Assignment';
import TimeTable from './pages/TimeTable';
import Gallery from './pages/Gallery';
import Navbar from './pages/Navbar';
import Layout from "./Component/Layout";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
            <Route path="/login" element={<Index />} />

          <Route path="/" element={<Layout />} >
        
            <Route index element={<Home />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/Communication" element={<Communication />} />
            <Route path="/Uniform" element={<Uniform />} />
            <Route path="/FeesPayment" element={<FeesPayment />} />
          
            <Route path="/Attendance" element={<Attendance />} />
            <Route path="/Calendar" element={<Calendar />} />
            <Route path="/Assignment" element={<Assignment />} />
            <Route path="/TimeTable" element={<TimeTable />} />
            <Route path="/Gallery" element={<Gallery />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

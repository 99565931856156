import { Link } from "react-router-dom";

export default function Assignment() {
  return(
    <>
      <section className="Profiles ">
  
  <div className="container-fluid">
    <div className=" py-3 d-flex">
    <h3 className="text-white fw-bold mx-auto">Assignment</h3> </div>
              
 
  </div>
</section>
<section className="w-100">
  <div className="container">
    <div className="row ">
      <div className="col-md-12   mt-2 ">
        <img src="./images/Attendance-1.svg" alt="" className="w-100"/>
      </div>
    </div>
  </div>
</section>
<section className="w-100">
  <div className="container">
    <div className="row py-4">
    <div className="col-sm-6 " >
      <h4  className="pt-5">28 - 07 - 2023</h4>
    </div>
    <div className="col-sm-6 " >
      <h4  className=" pt-5 text-end">Friday</h4>
    </div>

    </div>
    <div className="row my-5 py-5 px-2 bg-light">
      <div className="col-md-6">
<h2>ENGLISH</h2>
      </div>
      <div className="col-md-6 text-end">
        <select name="cars" id="cars" className="border-0 h2  p-2 rounded-2 ">
          <option value="volvo " className="">Ended On 28-07-2023</option>
          <option value="saab">29-07-2023</option>
          <option value="opel">30-07-2023</option>
          <option value="audi">31-07-2023</option>
        </select>

    </div>
  </div>
  </div>
</section>


<div className="w-100">
  <img src="./images/g5566.svg" alt="" className="w-100"/>
</div>
    
    </>
  )
}